/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';

const Container = styled.div`
  background: white;
  position: absolute;
  display: grid;
  width: 351px;
  grid-template-columns: minmax(0, 186px) minmax(0, 121px);
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 12px;
  left: 180px;
  bottom: 40px;
  box-shadow: '0px 2px 6px 0px rgba(48, 64, 120, 0.07), 0px 0px 2px 0px rgba(48, 66, 120, 0.06), 0px 0px 1px 0px rgba(48, 66, 120, 0.06)';
  cursor: pointer;
  z-index: 1;
  &:hover {
    background: ${colorsV2.neutral10};
  }
  ${fromScreen(1144)} {
    bottom: 20px;
    left: 43px;
  }
`;
const Name = styled.div``;
const Score = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const StudentInfo = ({ name, schoolName, score, type, handleClick }) => {
  return (
    <Container onClick={handleClick}>
      <Name>
        <Typography variant="semi-bold/16-24" color={colorsV2.black100}>
          {name}
        </Typography>
        <Typography variant="regular/14-20" color={colorsV2.black80}>
          {schoolName}
        </Typography>
      </Name>
      <Score>
        <Typography variant="semi-bold/40-48" color={colorsV2.primary100}>
          {score}
        </Typography>
        <Typography variant="regular/12-16" color={colorsV2.black60}>
          {type}
        </Typography>
      </Score>
    </Container>
  );
};
