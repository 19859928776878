import * as React from 'react';

import styled from 'styled-components';

import { withHomePageSection } from 'components/withHomePageSection';
import ExpandCollapsePanel from 'shared/SchedulePage/ExpandCollapsePanel';
import { fromScreen } from 'utils/media-query/responsive.util';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';

const FAQS = [
  {
    id: 1,
    question: 'Khóa học nhận các bạn học viên từ lớp mấy',
    content:
      'Chương trình IELTS Junior nhận học viên từ <strong>lớp 5 (10 tuổi) trở lên</strong>. Sau 3 năm nghiên cứu và phát triển sản phẩm, DOL đã hoàn thiện 18 khoá học: 18 cuốn sách, 18 bộ giảng bài, 18 chương trình đào tạo giáo viên, 18 bộ nội dung cho nền tảng công nghệ giúp học viên luyện tập ở nhà dành riêng cho học sinh từ 10-16 tuổi với kiến thức nền riêng, tư duy riêng và tâm lý riêng.'
  },
  {
    id: 2,
    question: 'Lộ trình học của bên mình như thế nào?',
    content: `Lộ trình học gồm 18 khoá học được xây dựng theo phương pháp Linearthinking độc quyền. Lộ trình được chia làm 2 giai đoạn:
- Giai đoạn 1: học viên học 6 cấp độ (levels), mỗi cấp độ 6 tháng, đầu ra sẽ là tương đương 6.0 IELTS.
- Giai đoạn 2: học viên theo các lớp luyện thi IELTS 6.0+ 6.5+ 7.0+ 7.5+ đầu ra sẽ là chứng chỉ IELTS 7.5 8.0. Nếu 1 bé học từ đầu lớp 5, và theo học liên tục chăm chỉ, thì cuối lớp 8 nhiều khả năng sẽ đạt được 7.5, 8.0 IELTS.`
  },
  {
    id: 3,
    question: 'Chương trình phù hợp cho phụ huynh nào',
    content: `Chương trình IELTS Junior tại DOL English được xây dựng nhằm hiện thực hóa những kỳ vọng của các bậc phụ huynh:
- Muốn con thành thạo 4 kỹ năng Nghe - Nói - Đọc - Viết, sử dụng tiếng Anh như ngôn ngữ mẹ đẻ, tự tin giao tiếp, thuyết trình và viết luận tiếng Anh
- Muốn con vừa chắc tiếng Anh học thuật, vừa đạt điểm cao tiếng Anh trên trường
- Muốn con được học tiếng Anh trong một môi trường tích cực, thân thiện, hiệu quả, an toàn, được quan tâm chăm sóc
- Muốn   con   được   tiếp  cận  với một phương pháp học tiếng Anh hoàn toàn mới, không học tủ, học mẹo hay nhồi nhét kiến thức mà đi sâu vào tư duy và bản chất, mang lại hiệu quả, học xong dùng được ngay
- Muốn con đạt điểm 7.0, 7.5, 8.0 IELTS ngay từ bậc THCS để có lợi thế trong việc du học hay xét tuyển vào các trường THPT top đầu thành phố
- Muốn  con  xây  dựng  nền  tảng tiếng Anh học thuật vững chắc ngay ở bậc THCS để tự tin chinh phục 7.0, 7.5, 8.0 IELTS ở bậc THPT, sẵn sàng cho kỳ thi xét tuyển cũng như săn học bổng giá trị tại các trường đại học top đầu trong nước và thế giới
- Muốn con rèn luyện được nhiều kỹ năng mềm như giao tiếp, thuyết trình; phát triển tư duy logic, phản biện và khả năng xử lý tình huống`
  },
  {
    id: 4,
    question: 'Lợi ích khi theo học chương trình IELTS Junior tại DOL',
    content: `Khi tham gia chương trình IELTS Junior tại DOL các con sẽ đạt được các mục tiêu sau:
- Chắc nền tiếng Anh học thuật: Chuẩn bị nền tảng tiếng Anh học thuật vững chắc cho kỳ thi IELTS/SAT
- Rèn luyện tư duy vượt ngoài phạm vi tiếng Anh: Tăng tư duy logic, tư duy phản biện và kiến thức xã hội bằng cả tiếng Việt lẫn tiếng Anh
- Thành thạo 4 kỹ năng Nghe - Nói - Đọc - Viết: Thành thạo cả 4 kỹ năng Nghe Nói Đọc Viết. Nghe xong có thể phản xạ nói được và viết được liền. Đọc 1 văn bản có thể tóm tắt, nói, viết lại được bằng ngôn ngữ của mình. Phát âm chuẩn, giọng hay như người bản xứ
- Được thiết kể để giúp học viên đạt 8.0 IELTS từ lớp 8, 9: Được thiết kể để giúp học viên đạt 8.0 IELTS từ lớp 8, 9
- ĐIỂM CAO tiếng Anh trên trường: Học giỏi và đạt điểm cao tiếng Anh trên trường (do chương trình DOL lồng kiến thức tiếng Anh trên trường nên sẽ bổ trợ rất nhiều)`
  },
  {
    id: 5,
    question: 'Phương pháp Linearthinking tại DOL là gì?',
    content: `Phương pháp học tại DOL là chú trọng tư duy, vận dụng; không phải học mẹo, học tủ để thi xong quên hết kiến thức.
Mình học để hiểu rõ bản chất vấn đề, giúp học viên có lối tư duy ngôn ngữ một cách logic, khoa học hơn -> không bị lan man mơ hồ.
Phương pháp tại DOL tập trung vào 3 phần kỹ năng:
- Reading: học cách đọc mạch ý và liên kết câu -> giúp học viên không bị phụ thuộc vào từ vựng khi đọc.
- Speaking & Writing: học cách phát triển và mở rộng ý -> không bị bí ý tưởng với chủ đề khó.`
  },
  {
    id: 6,
    question: 'Giáo viên tại DOL là ai ',
    content: `Các thầy cô tại DOL đều là các thầy cô có: 
- Chuyên môn xuất sắc: Sở hữu 8.0, 8.5, 9.0 IELTS và kỹ năng sư phạm tốt. Đội ngũ giáo viên nhiều năm kinh nghiệm, là Thạc sĩ, Thủ khoa, Á khoa, Giảng viên các trường Đại học danh tiếng. Có chứng chỉ giảng dạy quốc tế và được đào tạo bài bản.
- Dạy học có tâm: Đội ngũ giáo viên luôn đặt học viên của mình lên hàng đầu. Không chỉ giảng dạy và truyền đạt kiến thức tại lớp, giáo viên còn sẵn sàng hỗ trợ học viên ngoài giờ học. `
  },
  {
    id: 7,
    question: 'Khoá học có đảm bảo đầu ra không?',
    content: `DOL có đảm bảo đầu ra, miễn là học viên đảm bảo các tiêu chí sau:
- Đạt yêu cầu đầu vào của lớp
- Đi học chuyên cần trên 90% số buổi học
- Làm đủ bài tập theo phương pháp của DOL trên 90% số lượng bài tập
=> Nếu học viên thi cuối khoá hoặc đi thi thật (trong vòng 1 tháng sau khi kết thúc khoá học) chưa đủ điểm đầu ra DOL sẽ xếp học lại miễn phí theo đúng kỹ năng các bạn bị lệch điểm để có thể thi lại sớm nhất có thể`
  },
  {
    id: 8,
    question:
      'Nếu con có việc đột xuất, nghỉ học lý do chính đáng có được dạy bù không?',
    content: `DOL hỗ trợ học bù trong trường hợp bất khả kháng (ốm đau, bệnh tật, các kì thi quan trọng trên trường, v.v.).
1 khoá nghỉ tối đa 2 ngày để đảm bảo hiệu quả học cho các bạn học viên `
  },
  {
    id: 9,
    question: 'Phương thức và chính sách thanh toán học phí',
    content: `Hoàn tất học phí 1 lần trước khai giảng.
Có 2 hình thức: Chuyển khoản hoặc đóng tiền mặt tại trung tâm.`
  },
  {
    id: 10,
    question: 'Trung tâm có các cơ sở nào ',
    content: `Hiện tại bên DOL có 10 cơ sở ở TP HCM rất thuận tiện cho các bạn học viên theo học: 
- Cơ sở 1: Hẻm 458/14, 3 Tháng 2, P12, Q. 10, TP.HCM
- Cơ sở 2: 302 Nguyễn Văn Đậu, P11, Q.Bình Thạnh, TP.HCM
- Cơ sở 3: 24A Bàu Cát 2, Tân Bình, P.14, TP.HCM
- Cơ sở 4: 456 Nguyễn Thị Thập, P.Tân Quy, Q.7, TP.HCM
- Cơ sở 5: 95-97 Đường số 3, Khu Cityland Park Hills, P10, Gò Vấp, TP.HCM (đối diện Lotte Mart)
- Cơ sở 6: Tầng 4 - 25B Lê Văn Việt, Phường Hiệp Phú, Thành Phố Thủ Đức (sát Vincom Plaza), TP.HCM
- Cơ sở 7: 16 Đường số 3, P.11, Q.6 (sát bệnh viện quận 6, cổng đường Chợ Lớn), TP.HCM
- Cơ sở 8: 24/1 Võ Oanh (D3), Bình Thạnh, P.25, TP.HCM
- Cơ sở 9: 38/6 Nguyễn Văn Trỗi, Phường 15, Quận Phú Nhuận, TPHCM.
- Cơ sở 10: 9A Tô Vĩnh Diện, Phường Linh Chiểu, Thành Phố Thủ Đức, TPHCM.`
  }
];
/* eslint-disable react/prop-types */
const CourseFAQWrapper = styled.div`
  display: grid;
  grid-row-gap: 32px;
  .faq-section {
    color: ${colorsV2.black100};
    .expand-collapse-panel {
      background: ${colorsV2.gray5};
      border: 1px solid ${colorsV2.gray20};
      &.expanded {
        background: ${colorsV2.white100};
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .txt-description {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

const FAQGroup = styled.div`
  display: grid;
  grid-row-gap: 24px;

  .panel-content {
    word-break: break-word;
  }
`;

const CourseFAQ = () => {
  const [expandIndex, setExpandIndex] = React.useState(1);
  return (
    <CourseFAQWrapper>
      <FAQGroup>
        {FAQS.map(faq => {
          const { id, question, content } = faq;
          return (
            <div className="faq-section" key={id}>
              <ExpandCollapsePanel
                onExpandCollapseClick={() => {
                  setExpandIndex(prevExpandIndex => {
                    if (prevExpandIndex === id) return -1;
                    return id;
                  });
                }}
                expanded={expandIndex === id}
                title={question}
              >
                <p
                  style={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={{
                    __html: content
                  }}
                />
              </ExpandCollapsePanel>
            </div>
          );
        })}
      </FAQGroup>
    </CourseFAQWrapper>
  );
};

const HeadingContainer = styled.div`
  .title {
    margin-bottom: 16px;
  }
  .landing-title {
    margin-bottom: 24px;
  }

  ${fromScreen(776)} {
    .title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
    .landing-title {
      margin-bottom: 40px;
    }
  }

  ${fromScreen(1144)} {
    .landing-title {
      text-align: center;
    }
  }
`;

const Main = styled.div``;

export const IeltsJuniorFAQBase = props => {
  const landingTitle = {
    variant: 'bold/24-32',
    tabletVariant: 'bold/32-40',
    desktopVariant: 'bold/40-48',
    className: 'landing-title'
  };

  return (
    <Main {...props}>
      <HeadingContainer>
        <Typography
          {...landingTitle}
          v3
          as="h2"
        >{`Các câu hỏi thường gặp`}</Typography>
      </HeadingContainer>
      <CourseFAQ />
    </Main>
  );
};

const Container740 = styled.div`
  ${fromScreen(776)} {
    padding-bottom: 32px;
  }
  ${fromScreen(1144)} {
    position: relative;
  }
`;

export const IeltsJuniorFAQ = withHomePageSection(
  () => (
    <Container740 id="faq-section">
      <IeltsJuniorFAQBase />
    </Container740>
  ),
  { selfish: true }
);
