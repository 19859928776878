import React from 'react';
import PropTypes from 'prop-types';

import cl from 'classnames';
import styled from 'styled-components';
import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';

const Main = styled.div`
  .imgWrp {
    overflow: hidden;
    position: relative;
    margin-bottom: 1em;

    img {
      width: 100%;
    }
  }

  .name {
    font-size: 1em;
    margin: 0;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .occupation {
    font-size: 0.95em;
    color: #7a869a;
  }
  .row {
    margin-top: 12px;
    text-align: left;
  }
  .intake,
  .course {
    font-size: 14px;
    color: #7a869a;

    em {
      color: #d14242;
      font-style: normal;
    }
  }

  &.full {
    font-size: 20px;

    .imgWrp {
      width: 100%;
      height: unset;
      margin-bottom: 0.5em;
    }
  }

  @media screen and (min-width: 720px) {
    .imgWrp {
      width: 250px;
      height: 310px;
    }
  }
`;

const Img = styled.img`
  min-width: 416px;
  min-height: 582px;
  @media screen and ${deviceBreakpoints.mobile} {
    min-width: 310px;
    min-height: 432px;
  }
`;

const CertificateCard = ({
  name,
  occupation,
  certificate,
  full,
  className,
  ...props
}) => {
  return (
    <Main className={cl(className, { full })} {...props}>
      <div className="imgWrp">
        <Img src={certificate} loading="lazy" alt="certificate" />
      </div>

      <div className="row">
        <h6 className="name">{name}</h6>
        <div className="occupation">{occupation}</div>
      </div>
    </Main>
  );
};

CertificateCard.propTypes = {
  name: PropTypes.string,
  occupation: PropTypes.string,
  className: PropTypes.string,
  full: PropTypes.bool,
  certificate: PropTypes.string
};

export default CertificateCard;
