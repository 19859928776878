import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import cl from 'classnames';

const Main = styled.div`
  width: fit-content;
  background: transparent;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 12px;
  padding: 4px 16px 4px 4px;
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 90px;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  svg {
    width: 24px;
    height: 24px;
  }
  &.icon-primary {
    background: ${colorsV2.primary10};
  }
  &.icon-green {
    background: ${colorsV2.green10};
  }
  &.icon-yellow {
    background: ${colorsV2.yellow10};
  }
  &.icon-purple {
    background: ${colorsV2.purple10};
  }
  &.icon-blue {
    background: ${colorsV2.blue10};
  }
  &.icon-deepsky {
    background: ${colorsV2.DS10};
  }
`;

const BenefitCard = ({ text, color = 'primary', icon = <></>, ...rest }) => {
  return (
    <Main className={color} {...rest}>
      <Icon className={cl(`icon-${color}`)}>{icon}</Icon>
      <Typography variant="medium/18-24" color={colorsV2.black100}>
        {text}
      </Typography>
    </Main>
  );
};

BenefitCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
};

BenefitCard.defaultProps = {};

export default React.memo(BenefitCard);
