import * as React from 'react';
import Button from '@dolstaff/shared/es/Button';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { SymbolsCheckMarkBig } from 'components/Icon/SVGIcons';
import { StudentInfo } from './components/StudentInfo';
import { fromScreen } from 'utils/media-query/responsive.util';
import { graphql, useStaticQuery, Link } from 'gatsby';
import PopupButton from '@dolstaff/shared/es/PopupButton';
import CertificateCard from './components/CertificateCard';
import OutlineInterfaceCloss from '@dolstaff/shared/es/icons/OutlineInterfaceCloss';

const headerDescription = [
  'Chắc nền tiếng Anh học thuật',
  'Đạt 8.0 IELTS từ lớp 8, 9',
  'Thành thạo 4 kỹ năng Nghe - Nói - Đọc - Viết',
  'Điểm cao Tiếng Anh trên trường',
  'Phát âm chuẩn, hay, nói lưu loát',
  'Tăng tư duy logic, phản biện và kiến thức xã hội'
];

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  padding: 64px 0;
  .highlight {
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      background: ${colorsV2.yellow100};
      bottom: 3px;
      left: 0;
      height: 10px;
      width: 100%;
      opacity: 0.3;
      z-index: -1;
    }
  }
  ${fromScreen(776)} {
    padding: 100px 0;
  }
  ${fromScreen(1144)} {
    grid-template-columns: minmax(0, 576px) minmax(0, 436px);
    justify-content: space-between;
  }
`;

const LeftSection = styled.div`
  display: grid;
  gap: 24px;
  padding: 0;
  > a {
    margin-top: 16px;
    width: fit-content;
  }
  ${fromScreen(1144)} {
    padding: 25px 0;
  }
  .register {
    border-radius: 8px;
  }
`;
const RightSection = styled.div`
  position: relative;
  display: none;
  justify-content: center;
  .right-section-image {
    position: relative;
    width: 436px;
    top: -40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .icon-left {
    position: absolute;
    left: 80px;
    bottom: 140px;
  }
  .icon-top {
    position: absolute;
    right: 125px;
  }
  .icon-bottom {
    position: absolute;
    bottom: 35px;
    right: 100px;
  }
  ${fromScreen(776)} {
    display: grid;
  }
  ${fromScreen(1144)} {
    display: block;
    width: 100%;
    .right-section-image {
      position: absolute;
      top: -30px;
    }
    .icon-left {
      left: -60px;
      bottom: 95px;
    }
    .icon-top {
      right: 0;
    }
    .icon-bottom {
      bottom: 20px;
      right: -5px;
    }
  }
`;
const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
`;
const Item = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 14px;
  align-items: center;
  padding: 8px 0;
`;

const PopupButtonWrapper = styled(PopupButton)``;

const BtnWrp = styled.div`
  position: absolute;
  right: -1em;
  top: -1em;
  z-index: 2;
`;
const CloseBtn = styled.button`
  padding: 12px;
  background-color: white;
  color: black;
  border: 0;
  outline: none;
  cursor: pointer;
  top: 3px;
  position: relative;
`;

const Header = ({ data }) => {
  const {
    name,
    type,
    score,
    imageSrc,
    schoolName,
    formLink,
    certificateImageSrc
  } = data || {};
  return (
    <Container>
      <LeftSection>
        <Typography variant="bold/32-40" desktopVariant="bold/40-48" as="h1" v3>
          Chương trình <span className="highlight">DOL </span>
          <span className="highlight"> IELTS </span>
          <span className="highlight"> Junior</span> dành cho học sinh THCS
        </Typography>
        <Typography variant="medium/18-24" color={colorsV2.neutralPrimary} v3>
          Chương trình phù hợp với phụ huynh muốn con:
        </Typography>
        <List>
          {headerDescription.map((eachDes, idx) => (
            <Item key={idx}>
              <SymbolsCheckMarkBig color={colorsV2.primary100} />
              <Typography
                variant="medium/16-24"
                color={colorsV2.neutralPrimary}
                v3
              >
                {eachDes}
              </Typography>
            </Item>
          ))}
        </List>
        <Link
          rel="nofollow noreferrer noopener"
          target="_blank"
          href={formLink}
        >
          <Button
            size="large"
            className="btn-action register"
            type="primary"
            marginBottom="0"
          >
            <Typography variant="semi-bold/14-24" color={colorsV2.white100}>
              Đăng ký sớm giữ chỗ
            </Typography>
          </Button>
        </Link>
      </LeftSection>

      <RightSection>
        <LazyImage
          className="right-section-image"
          src={imageSrc}
          alt="image-header"
        />
        <PopupButtonWrapper
          hideCloseButton
          hideOnBackdropClick
          isCentered
          zIndex={600}
          style={{
            width: '100%',
            maxWidth: '500px',
            padding: '14px 16px'
          }}
          trigger={({ toggleContent }) => (
            <StudentInfo
              name={name}
              schoolName={schoolName}
              score={score}
              type={type}
              handleClick={() => toggleContent()}
            />
          )}
        >
          {({ toggleContent }) => (
            <div style={{ position: 'relative' }}>
              <BtnWrp>
                <CloseBtn onClick={() => toggleContent()}>
                  <OutlineInterfaceCloss size={24} />
                </CloseBtn>
              </BtnWrp>
              <CertificateCard
                full
                name={name}
                occupation={schoolName}
                certificate={certificateImageSrc}
              />
            </div>
          )}
        </PopupButtonWrapper>

        <LazyImage
          handle="pZtoUY3mStuWmaQ5lBx6"
          className="icon-left"
          alt="icon-arrow"
        />
        <LazyImage
          handle="RY1uavGWQqW2aZ0GGJDo"
          className="icon-top"
          alt="icon-start"
        />
        <LazyImage
          handle="1KmuTBLmS4uEu98y6Tp3"
          className="icon-bottom"
          alt="icon-wavy"
        />
      </RightSection>
    </Container>
  );
};

export const IeltsJuniorHeader = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "Ielts_Junior_Header" }) {
            content
          }
        }
      }
    `);
    return <Header data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
