import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { IeltsJuniorHeader } from 'shared/IeltsJunior/IeltsJuniorHeader';
import { AboutIeltsJunior } from 'shared/IeltsJunior/AboutIeltsJunior';
// import { IeltsJuniorIntroduction } from 'shared/IeltsJunior/IeltsJuniorIntroduction';
import { IeltsJuniorRoadmap } from 'shared/IeltsJunior/IeltsJuniorRoadmap';
import { ModernFacilities } from 'shared/IeltsJunior/ModernFacilities';
import { IeltsJuniorBenefits } from 'shared/IeltsJunior/IeltsJuniorBenefits';
// import HallOfFame from 'shared/HallOfFameV2';
import IeltsJuniorDifferencesHome from 'shared/IeltsJunior/IeltsJuniorDifferencesHome';
import AllDolTeachersV3 from 'shared/DolTeachers/AllDolTeachersV3';
import { SaleBannerSection } from 'shared/IeltsJunior/SaleBannerSection';
import { IeltsJuniorFAQ } from 'shared/IeltsJunior/IeltsJuniorFAQ';

const IeltsJunior = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="Chương trình DOL IELTS Junior dành cho học sinh THCS"
        slug={location.pathname}
        seoTitle="Chương trình DOL IELTS Junior dành cho học sinh THCS"
        seoDesc="Chương trình phù hợp với phụ huynh muốn con: Chắc nền tiếng Anh học thuật, Đạt 8.0 IELTS từ lớp 8, 9, thành thạo 4 kỹ năng Nghe - Nói - Đọc - Viết, điểm cao Tiếng Anh trên trường, phát âm chuẩn, hay, nói lưu loát, tăng tư duy logic, phản biện và kiến thức xã hội"
        description="Chương trình phù hợp với phụ huynh muốn con: Chắc nền tiếng Anh học thuật, Đạt 8.0 IELTS từ lớp 8, 9, thành thạo 4 kỹ năng Nghe - Nói - Đọc - Viết, điểm cao Tiếng Anh trên trường, phát âm chuẩn, hay, nói lưu loát, tăng tư duy logic, phản biện và kiến thức xã hội"
        featureImg="1le7VvNTqqwYfQNVbwTo"
      />
      <FixedTopBarScholarshipScheduleNOffline />
      <IeltsJuniorHeader />
      {/* <IeltsJuniorIntroduction /> */}
      <IeltsJuniorRoadmap />
      <AboutIeltsJunior />
      <Space>
        {/* <HallOfFame /> */}
        <IeltsJuniorDifferencesHome />
        <ModernFacilities />
        <IeltsJuniorBenefits />
        <AllDolTeachersV3 />
        <SaleBannerSection />
      </Space>
      <IeltsJuniorFAQ />
    </MainLayout>
  );
};

const Space = styled.div`
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
`;

export default React.memo(IeltsJunior);
