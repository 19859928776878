/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import {
  IconsCommunicationMessageChat,
  IconsLearningMonitorGraduateHat,
  IconsLearningBooksAppleV2,
  IconsOtherFlash,
  IconsLearningSpeakingSample,
  IconsFeatureBulbV2
} from 'components/Icon/SVGIcons';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';
import { withHomePageSection } from 'components/withHomePageSection';
import { Link } from 'gatsby';
import ButtonText from 'components/ButtonText';
import BenefitCard from './components/BenefitCard';

const BENEFITS = [
  {
    icon: (
      <IconsCommunicationMessageChat
        width={24}
        height={24}
        color={colorsV2.yellow100}
      />
    ),
    text: 'Chắc nền tiếng Anh học thuật',
    color: 'yellow'
  },
  {
    icon: (
      <IconsLearningMonitorGraduateHat
        width={24}
        height={24}
        color={colorsV2.DS100}
      />
    ),
    text: 'Đạt 8.0 IELTS từ lớp 8, 9',
    color: 'deepsky'
  },
  {
    icon: (
      <IconsLearningBooksAppleV2
        width={24}
        height={24}
        color={colorsV2.primary100}
      />
    ),
    text: 'Thành thạo 4 kỹ năng Nghe - Nói - Đọc - Viết',
    color: 'primary'
  },
  {
    icon: <IconsOtherFlash width={24} height={24} color={colorsV2.blue100} />,
    text: 'Điểm cao Tiếng Anh trên trường',
    color: 'blue'
  },
  {
    icon: (
      <IconsLearningSpeakingSample
        width={24}
        height={24}
        color={colorsV2.purple100}
      />
    ),
    text: 'Phát âm chuẩn, hay, nói lưu loát',
    color: 'purple'
  },
  {
    icon: (
      <IconsFeatureBulbV2 width={24} height={24} color={colorsV2.green100} />
    ),
    text: 'Tăng tư duy logic, phản biện và kiến thức xã hội',
    color: 'green'
  }
];

const Main = styled.div`
  position: relative;
  display: grid;
  gap: 48px;
  .benefit-image {
    display: none;
    width: 552px;
    position: absolute;
    right: 0;
    bottom: -250px;
  }
  ${fromScreen(776)} {
    .benefit-image {
      display: block;
      right: -230px;
    }
  }
  ${fromScreen(1144)} {
    .benefit-image {
      display: block;
      right: 0;
    }
  }
`;
const HeaderTitle = styled.div`
  width: 100%;
  ${fromScreen(776)} {
    width: 555px;
  }
  ${fromScreen(1144)} {
    width: 736px;
  }
`;
const Benefits = styled.div`
  display: grid;
  gap: 20px;
`;

const IeltsJuniorBenefitsBase = () => {
  return (
    <Main>
      <HeaderTitle>
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          color={colorsV2.black100}
          v3
        >
          Lợi ích học viên nhận được sau chương trình
        </Typography>
      </HeaderTitle>
      <Benefits>
        {BENEFITS.map((eachBenefit, idx) => (
          <BenefitCard
            key={idx}
            icon={eachBenefit.icon}
            text={eachBenefit.text}
            color={eachBenefit.color}
          />
        ))}
      </Benefits>
      <Link to="/dam-bao-dau-ra">
        <ButtonText
          content="Chính sách hỗ trợ học lại miễn phí tại DOL"
          type="primary"
        />
      </Link>
      <LazyImage
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/59ec6bc9-ab8d-4eac-b641-e0c2ef53b329.png"
        alt="ielts-junior-benefit"
        className="benefit-image"
      />
    </Main>
  );
};

export const IeltsJuniorBenefits = withHomePageSection(
  () => {
    return <IeltsJuniorBenefitsBase />;
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
