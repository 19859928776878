/* eslint-disable react/prop-types */
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components/macro';
import {
  IconCourseArrowRight,
  IconsMessageIcon
} from 'components/Icon/SVGIcons';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { useDeviceDetect3 } from 'utils/hooks/useDeviceQuery.hook';
import Lightbox from 'react-image-lightbox';
import { fromScreen } from 'utils/media-query/responsive.util';
import { useMounted } from 'utils/hooks/useMounted';
import { withHomePageSection } from 'components/withHomePageSection';
import { HeadlineIcon } from 'components/HeadlineIcon';

const HoverState = styled.div`
  border-radius: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  position: absolute;
  background: rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: 0.2s ease;
  color: ${colorsV2.white100};
  cursor: pointer;

  .see-more {
    display: flex;
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  &:hover {
    ${HoverState} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const Main = styled.div`
  .section-title {
    justify-content: center;
  }
`;

const Intro = styled.div`
  display: grid;
  grid-template-columns: 32px minmax(0, 1fr);
  justify-content: center;
  gap: 24px;
  margin: 24px 0 48px;
  svg {
    width: 32px;
    height: 34px;
  }
  ${fromScreen(776)} {
    grid-template-columns: 32px 600px;
  }
`;

const LastItem = styled.div`
  position: relative;
  ${HoverState} {
    visibility: visible;
    opacity: 1;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 128px);
  gap: 16px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  div {
    height: 100%;
    &:first-child {
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }

  ${fromScreen(776)} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(2, 218px);
    gap: 24px;

    div {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 5;
      }
    }
  }

  ${fromScreen(1144)} {
    grid-template-rows: repeat(2, 344px);
  }
`;

const ModernFacilitiesBase = ({ data }) => {
  const mounted = useMounted();
  const [index, setIndex] = React.useState(0);
  const [isOpen, setOpen] = React.useState(false);
  const { isMobile } = useDeviceDetect3();
  let limitImage;
  if (mounted) {
    if (isMobile) {
      limitImage = 3;
    } else {
      limitImage = 4;
    }
  }

  const onImageView = idx => {
    setOpen(true);
    setIndex(idx);
  };

  return (
    <Main>
      <HeadlineIcon iconHandle="0GYERmATmSRJWjiRPE4w" className="section-title">
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Cơ sở vật chất hiện đại
        </Typography>
      </HeadlineIcon>
      <Intro>
        <IconsMessageIcon />
        <Typography
          variant="regular/16-28"
          desktopVariant="regular/20-28"
          className="intro"
          v3
          color={colorsV2.neutral140}
        >
          {`DOL đầu tư vào cơ sở vật chất “xịn xò", hiện đại nhất (phòng học thông
          thoáng và sạch sẽ, phòng tự học yên tĩnh, sảnh chờ,…) tạo môi trường học
          thoải mái và tiện nghi nhất cho học viên.`}
        </Typography>
      </Intro>

      <ImageGrid>
        {data.slice(0, limitImage).map((img, idx, { length }) => {
          const lastItem = length - 1 === idx;
          if (!lastItem) {
            return (
              <ImageWrapper onClick={() => onImageView(idx)} key={idx}>
                <LazyImage src={img} alt="Cơ sở của DOL" />
                <HoverState>
                  <Typography
                    variant="semi-bold/14-20"
                    className="see-more"
                    color={colorsV2.white100}
                  >
                    Xem chi tiết <IconCourseArrowRight />
                  </Typography>
                </HoverState>
              </ImageWrapper>
            );
          }
          return (
            <LastItem key={idx} onClick={() => onImageView(idx)}>
              <LazyImage src={img} alt="Cơ sở của DOL" />
              <HoverState>
                <Typography
                  variant="semi-bold/14-20"
                  className="see-more"
                  color={colorsV2.white100}
                >
                  Xem thêm hình ảnh <IconCourseArrowRight />
                </Typography>
              </HoverState>
            </LastItem>
          );
        })}
      </ImageGrid>
      {isOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 200000
            }
          }}
          mainSrc={data[index]}
          nextSrc={data[(index + 1) % data.length]}
          prevSrc={data[(index + data.length - 1) % data.length]}
          onCloseRequest={() => {
            setOpen(false);
            setIndex(limitImage - 1);
          }}
          onMovePrevRequest={() =>
            setIndex((index + data.length - 1) % data.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % data.length)}
        />
      )}
    </Main>
  );
};

export const ModernFacilities = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "Office_Image_V3" }) {
            content
          }
        }
      }
    `);
    return (
      <ModernFacilitiesBase data={data.gcms.jsonContanier.content.images} />
    );
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
