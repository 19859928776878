import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';
import { CardVideo } from './components/CardVideo';
import Modal from '../CourseLanding/Modal';
import {
  IconArrowChevronLeftArrowLeft,
  IconArrowChevronLeftArrowRight,
  IconsContentQuote
} from 'components/Icon/SVGIcons';
import VideoInline from 'shared/VideoThumbnailInline/VideoInline';
import cl from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import { LazyImage } from 'components/LazyImage';
import { graphql, useStaticQuery } from 'gatsby';

const Container = styled.div`
  padding: 64px 0;
  ${fromScreen(776)} {
    padding: 100px 0;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  width: 100%;
  ${fromScreen(1144)} {
    width: 466px;
  }
`;

const Content = styled.div`
  margin-top: 64px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 40px;
  gap: 12px;
  justify-content: end;
  align-items: end;
  &.buttom-top {
    display: none;
    margin-top: 0;
  }
  &.buttom-bottom {
    display: grid;
    margin-top: 48px;
  }
  ${fromScreen(776)} {
    &.buttom-top {
      display: grid;
    }
    &.buttom-bottom {
      display: none;
    }
  }
`;
const ButtonRound = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colorsV2.primary10};
  border-radius: 40px;
  cursor: pointer;
  &:hover {
    background: ${colorsV2.primary20};
  }
`;

const VideoElement = styled(VideoInline)`
  video {
    border-radius: 8px;
  }
  > div {
    margin-bottom: 0;
  }
`;

const CardInfoItem = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 24px;
  padding: 16px;
  margin: 0;
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 24px;
  .card-video {
    width: 234px;
    height: 420px;
  }
  ${fromScreen(776)} {
    grid-template-columns: minmax(0px, 234px) auto;
  }
`;
const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
`;
const Description = styled.div`
  display: grid;
  gap: 12px;
  svg {
    width: 32px;
    height: 32px;
  }
`;
const Info = styled.div`
  margin-top: 12px;
  display: grid;
  gap: 8px;
`;

const ContentInfo = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 68px);
  gap: 12px;
`;
const LeftContentInfo = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 40px) auto;
  align-items: center;
  gap: 12px;
  img {
    border-radius: 50%;
  }
`;
const RightContentInfo = styled.div``;
const StudentName = styled.div`
  display: grid;
  gap: 8px;
`;
const Score = styled.div`
  height: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 12px;
`;

const AboutIeltsJuniorBase = ({ data }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');

  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 1,
    spacing: 8,
    breakpoints: {
      '(min-width: 1144px)': {
        slidesPerView: 1.5,
        spacing: 24
      }
    },
    loop: true,
    duration: 500,
    mode: 'free-snap'
  });

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            desktopVariant="bold/40-48"
            color={colorsV2.black100}
            v3
          >
            Phụ huynh nói gì về DOL Junior
          </Typography>
        </HeaderTitle>
        <ButtonWrapper className="buttom-top">
          <ButtonRound
            onClick={() => {
              if (slider) {
                slider.prev();
              }
            }}
          >
            <IconArrowChevronLeftArrowLeft
              width={24}
              height={24}
              color={colorsV2.primary100}
            />
          </ButtonRound>
          <ButtonRound
            onClick={() => {
              if (slider) {
                slider.next();
              }
            }}
          >
            <IconArrowChevronLeftArrowRight
              width={24}
              height={24}
              color={colorsV2.primary100}
            />
          </ButtonRound>
        </ButtonWrapper>
      </Header>
      <Content>
        <div ref={sliderRef} className="keen-slider">
          {data?.map((eachVideo, idx) => (
            <CardInfoItem
              key={idx}
              className={cl('keen-slider__slide', 'card-info')}
            >
              <CardVideo
                data={eachVideo}
                onPlayVideo={() => {
                  setVideoUrl(eachVideo?.videoUrl);
                  setShowModal(true);
                }}
              />
              <RightSide>
                <Description>
                  <IconsContentQuote />
                  <Typography
                    variant="medium/18-24"
                    color={colorsV2.neutral180}
                    v3
                  >
                    {eachVideo?.description}
                  </Typography>
                </Description>
                <Info>
                  <Typography
                    variant="regular/16-20"
                    color={colorsV2.neutral120}
                    v3
                  >
                    Phụ huynh của em
                  </Typography>

                  <ContentInfo>
                    <LeftContentInfo>
                      <LazyImage
                        className=""
                        src={eachVideo?.avatar}
                        alt="student-avatar"
                      />
                      <StudentName>
                        <Typography
                          variant="semi-bold/18-24"
                          color={colorsV2.neutral180}
                          v3
                        >
                          {eachVideo?.fullName}
                        </Typography>
                        <Typography
                          variant="mediun/16-20"
                          color={colorsV2.neutral120}
                          v3
                        >
                          {eachVideo?.schoolName}
                        </Typography>
                      </StudentName>
                    </LeftContentInfo>
                    <RightContentInfo>
                      <Score>
                        <Typography
                          variant="semi-bold/28-36"
                          color={colorsV2.primary100}
                          v3
                        >
                          {eachVideo?.score}
                        </Typography>
                        <Typography
                          variant="regular/14-16"
                          color={colorsV2.neutralPrimary}
                          v3
                        >
                          {eachVideo?.type}
                        </Typography>
                      </Score>
                    </RightContentInfo>
                  </ContentInfo>
                </Info>
              </RightSide>
            </CardInfoItem>
          ))}
        </div>
      </Content>
      <ButtonWrapper className="buttom-bottom">
        <ButtonRound
          onClick={() => {
            if (slider) {
              slider.prev();
            }
          }}
        >
          <IconArrowChevronLeftArrowLeft
            width={24}
            height={24}
            color={colorsV2.primary100}
          />
        </ButtonRound>
        <ButtonRound
          onClick={() => {
            if (slider) {
              slider.next();
            }
          }}
        >
          <IconArrowChevronLeftArrowRight
            width={24}
            height={24}
            color={colorsV2.primary100}
          />
        </ButtonRound>
      </ButtonWrapper>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <VideoElement
          onContextMenu={e => e.preventDefault()}
          video={videoUrl}
          alt="talk-about-dol-english"
          controlsList="nodownload"
          isAutoPlay
        />
      </Modal>
    </Container>
  );
};

export const AboutIeltsJunior = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "Ielts_Junior_About_Dol" }) {
            content
          }
        }
      }
    `);
    return <AboutIeltsJuniorBase data={data.gcms.jsonContanier.content.info} />;
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
